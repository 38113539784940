<template>
  <div class="failure">
    <GlobalInfoBar title="快捷备注" content="快捷备注将用于门店端小程序-首页报价时展示，提供快捷选择"/>
    <GlobalChunk icon="search" title="快捷备注选项">
      <div class="case-title">
        <div style="text-align: left;margin-bottom: 20px">
          <el-button size="small" type="primary" icon="el-icon-plus" @click="AddFailure">添加</el-button>
        </div>
        <div class="case-title_min">说明: 添加的快捷备注将显示在门店端小程序-首页报价界面（字数限制10字内）</div>
      </div>


      <el-table
          border

          :data="faultTypeList.records"
      >
        <el-table-column
            type="index"
            width="100"
            align="center" label="序号"
        >
        </el-table-column>
        <el-table-column align="center" label="故障名称" prop="name"/>
        <el-table-column align="center" label="添加时间" prop="createTime" width="180"/>
        <el-table-column v-slot="{row}" align="center" :label="item.title" :key="item.key" width="100" :prop="item.key" v-for="item in switchData">
          <el-switch
              active-text="开"
              inactive-text="关"
              inactive-color="#b6b9d0"
              @change="(v)=>switchChange(v,row,item)"
              v-model="row[item.key]">
          </el-switch>
        </el-table-column>
        <el-table-column align="center" label="排序" width="200">
          <template slot-scope="scope">
            <el-input-number size="small" v-model="scope.row.sort" :min='0'
                             @change="handleChange(scope.row)"></el-input-number>
          </template>
        </el-table-column>

        <el-table-column align="center" label="操作" width="423">
          <template slot-scope="scope">
            <el-button @click.native.prevent="deleteBtn(scope.row)" type="danger">删除</el-button>
            <el-button @click.native.prevent="dblclick(scope.row)" type="primary">编辑</el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
          style="text-align:right;margin-right:200px"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="currentPage4"
          layout="total,  prev, pager, next, jumper"
          :total="faultTypeList.total"
          :background='true'
      >
      </el-pagination>
      <!-- <div class="faultBtn">
        <div class="faultBut_item" v-for="(item) in faultTypeList.records" :key="item.id" @dblclick="dblclick(item)">
          {{item.name}}
          <i class="el-icon-close" @click="deleteBtn(item)" />
        </div>
      </div> -->
    </GlobalChunk>
    <!--弹框-->
    <GlobalDialog
        :title="dialogTitle"
        :show="dialogVisible"
        :width="dialogWidth"
        @resetPopupData="resetPopupData"
        @submitPopupData="submitPopupData"
        @onClose="onClose"
    >
      <div class="AddDialog" v-if="this.removeFaultAdd === 'AddBtn'||this.removeFaultAdd === 'EditBtn'">
        <div class="title">说明：添加的故障将显示在用户端报价页面</div>
        <div style="margin:40px 0;">
          <el-input v-model="AddTextarea" placeholder="请输入故障类型（10字内)" maxlength="10" clearable></el-input>
        </div>
      </div>
      <div style="font-size:16px;color:#666666;text-align:center;margin-bottom:10px;text-align:left;"
           v-if="this.removeFaultAdd === 'deleteBtn'">是否确认删除该快捷备注？
      </div>
    </GlobalDialog>
    <!-- 四种状态上架家操作  -->
    <DialogAction
        v-bind="switchDialogProps"
        @config="switchConfig"
        @close="switchDialogProps.show = false;handleCurrentChange(currentPage4)"
    />
  </div>
</template>

<script>
import _api from "@/utils/request";
import DialogAction from "@/components/common/DialogAction.vue";

export default {
  name: "failure",
  components: {DialogAction},
  data() {
    return {
      switchDialogProps:{
        show:false,
        title:"上架提示",
        isTip:true,
        tipText:"",
        row:"",
        item:"",
        value:false
      },
      currentPage4: 1,
      num: 0,
      dialogVisible: false, // 弹框的出现与否
      dialogTitle: "", // 标题
      dialogWidth: 380,
      faultTypeList: [],
      removeFaultAdd: "",
      AddTextarea: "",
      FaultId: "",
      // 开关数据源

      switchData: [
        {
          key:'canStartUp',
          title:'正常开机',
        },
        {
          key:'notStartUp',
          title: "不能开机"
        },
        {
          key:'fireNew',
          title:"全新机"
        },
        {
          key:'senile',
          title: "功能机/老年机"
        }
      ]
    };
  },
  created() {
    this.FaultBtnList();
  },
  methods: {
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
      this.currentPage4 = val
      _api.listFault({

        'pageNum': val,
        'machineTypeId': 1,
        'pageSize': 10

      }).then(res => {
        if (res.code === 1) {
          this.faultTypeList = res.data;
          console.log(this.faultTypeList);
        }
      });
    },
    // 开关切换
    switchChange(v,row,item){
        this.switchDialogProps = {
          ...this.switchDialogProps,
          show:true,
          tipText: `确认${v?'开启':'关闭'}“${item.title}”备注选项`,
          row,
          item,
          value:v
        }
    },
    // 开关确定
    switchConfig(){
      const {row,item,value} = this.switchDialogProps;
      let switchData = {};
      this.switchData.forEach(v=>{
          switchData[v.key] = row[v.key];
      })
      const data = {
        id:row.id,
        ...switchData,
        [item.key]:value,
      }
      _api.faultSetConfig(data).then(r=>{
        this.$message.success("操作成功")
        this.switchDialogProps = {
          ...this.switchDialogProps,
          show:false,
        }
        this.handleCurrentChange(this.currentPage4)
      })
    },


    handleChange(row) {
      console.log(row.sort, '排序值');
      _api.saveupdateSort({
        id: row.id,
        sort: row.sort
      }).then(res => {
        console.log(res);

        _api.listFault({

          'pageNum': this.currentPage4,
          'machineTypeId': 1,
          'pageSize': 10

        }).then(res => {
          if (res.code === 1) {
            this.faultTypeList = res.data;
            console.log(this.faultTypeList);
          }
        });
      })
    },

    Fn(row) {
      console.log(row, "打印");
    },
    // 类型按钮数组请求
    FaultBtnList() {
      _api.listFault({

        'pageNum': 1,
        'machineTypeId': 1,
        'pageSize': 10

      }).then(res => {
        if (res.code === 1) {
          this.faultTypeList = res.data;
          console.log(this.faultTypeList);
        }
      });
    },
    // 双击编辑按钮
    dblclick(row) {
      console.log(row)
      this.dialogTitle = "编辑选项"
      this.removeFaultAdd = "EditBtn";
      this.FaultId = row.id;
      this.AddTextarea = row.name
      this.dialogWidth = 600
      this.dialogVisible = true;
    },
    //添加故障类型按钮
    AddFailure() {
      this.AddTextarea = ''
      this.dialogTitle = "添加选项"
      this.removeFaultAdd = "AddBtn";
      this.dialogWidth = 600
      this.dialogVisible = true;
    },
    //删除故障类型按钮
    deleteBtn(row) {
      this.dialogTitle = "删除选项"
      this.FaultId = row.id;
      this.dialogWidth = 380
      this.removeFaultAdd = "deleteBtn";
      this.dialogVisible = true;
    },
    // //弹窗取消
    resetPopupData() {
      this.dialogVisible = false;
    },
    //弹窗确定
    submitPopupData() {
      this.dialogVisible = false;
      //删除
      if (this.removeFaultAdd === "deleteBtn") {
        const id = JSON.parse(this.FaultId);
        _api.removeFault({id}).then(res => {
          if (res.code === 1) {
            this.FaultBtnList();
            this.$message({
              message: "删除成功",
              type: "success"
            });
          } else {
            this.$message({
              message: "删除失败",
              type: "error"
            });
          }
        });
      }
      //添加
      else if (this.removeFaultAdd === "AddBtn") {
        const dto = {
          name: this.AddTextarea
        }
        _api.addFault(dto).then(res => {
          if (res.code === 1) {
            this.FaultBtnList();
            this.$message({
              message: "添加成功",
              type: "success"
            });
          } else {
            this.$message({
              message: "添加失败",
              type: "error"
            });
          }
        });
      }
      //编辑
      else if (this.removeFaultAdd === "EditBtn") {
        const dto = {
          name: this.AddTextarea,
          id: this.FaultId
        }
        _api.editFault(dto).then(res => {
          if (res.code === 1) {
            this.FaultBtnList();
            this.$message({
              message: "编辑成功",
              type: "success"
            });
          } else {
            this.$message({
              message: "编辑失败",
              type: "error"
            });
          }
        });
      }
    },
    onClose() {
      this.dialogVisible = false;
    }
  }
};
</script>

<style scoped lang="scss" ref="stylesheet/scss">
.failure {
  .case-title {
    display: flex;

    .case-title_min {
      margin-left: 5px;
      font-size: 12px;
      font-family: FZLanTingHei-M-GBK;
      font-weight: 400;
      color: #ff687b;
      line-height: 45px;
    }
  }

  .faultBtn {
    display: flex;
    flex-wrap: wrap;
    background: #f5f6fa;
    width: 100%;

    .faultBut_item {
      user-select: none;
      align-items: center;
      margin: 10px 25px;
      height: 30px;
      background: #ffffff;
      border: 1px solid #0981ff;
      border-radius: 15px;
      text-align: center;
      line-height: 30px;
      font-size: 14px;
      color: #0981ff;
      padding: 0 5px;
    }

    .faultBut_item:hover {
      height: 30px;
      background: #0981ff;
      font-size: 14px;
      border: 1px solid #0981ff;
      margin: 10px 25px;
      color: white;
      border-radius: 15px;
      text-align: center;
      line-height: 30px;
      cursor: pointer;
    }
  }

  .AddDialog {
    .title {
      width: 232px;
      height: 12px;
      font-size: 12px;
      font-family: FZLanTingHei-M-GBK;
      font-weight: 400;
      color: #FF687B;
      margin-bottom: 20px;
    }
  }
}

/deep/ .el-switch__label * {
  line-height: 1;
  font-size: 12px;
  display: inline-block;
}

/deep/ .el-switch__label {
  position: absolute;
  display: none;
  color: #fff !important;
  font-size: 12px !important;
}

/*打开时文字位置设置*/
/deep/ .el-switch__label--right {
  z-index: 1;
  right: 4px;
}

/*关闭时文字位置设置*/
/deep/ .el-switch__label--left {
  z-index: 1;
  left: 4px;
}

/*显示文字*/
/deep/ .el-switch__label.is-active {
  display: block;
}

/*开关宽度*/
/deep/ .el-switch .el-switch__core,
/deep/ .el-switch .el-switch__label {
  width: 50px !important;
}
</style>
